/* You can add global styles to this file, and also import other style files */
/* Provide sufficient contrast against white background */
@font-face {
  font-family: "HelveticaNeueLTStd-Bd";
  src: url("./assets/fonts/HelveticaNeueLTStd-Bd.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/HelveticaNeueLTStd-Bd.otf") format("opentype"), url("./assets/fonts/HelveticaNeueLTStd-Bd.woff") format("woff"), url("./assets/fonts/HelveticaNeueLTStd-Bd.ttf") format("truetype"), url("./assets/fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("./assets/fonts/HelveticaNeueLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/HelveticaNeueLTStd-Roman.otf") format("opentype"), url("./assets/fonts/HelveticaNeueLTStd-Roman.woff") format("woff"), url("./assets/fonts/HelveticaNeueLTStd-Roman.ttf") format("truetype"), url("./assets/fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Md";
  src: url("./assets/fonts/HelveticaNeueLTStd-Md.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype"), url("./assets/fonts/HelveticaNeueLTStd-Md.woff") format("woff"), url("./assets/fonts/HelveticaNeueLTStd-Md.ttf") format("truetype"), url("./assets/fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Lt";
  src: url("./assets/fonts/HelveticaNeueLTStd-Lt.eot") format("embedded-opentype"), url("./assets/fonts/HelveticaNeueLTStd-Lt.otf") format("opentype"), url("./assets/fonts/HelveticaNeueLTStd-Lt.woff") format("woff"), url("./assets/fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype"), url("./assets/fonts/HelveticaNeueLTStd-Lt.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.hel75,
.bold {
  font-family: "HelveticaNeueLTStd-Bd";
}

.hel55 {
  font-family: "HelveticaNeueLTStd-Roman";
}

.hel65 {
  font-family: "HelveticaNeueLTStd-Md";
}

.hel45 {
  font-family: "HelveticaNeueLTStd-Lt";
}

.mb-10 {
  margin-bottom: 10px;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.bcolor {
  color: #000000;
}

.theme-profile_c {
  color: #049a8f;
}

.theme-package_c {
  color: #a50095;
}

.theme-product_c {
  color: #f6bf52;
}

.theme-process_c {
  color: #0046ac;
}

.theme-sign_b {
  background: #092347;
}

.theme-palletization_c {
  color: #34C6F4;
}

.theme-profile_b {
  background-color: #049a8f;
}

.theme-package_b {
  background-color: #a50095;
}

.theme-product_b {
  background-color: #f6bf52;
}

.theme-process_b {
  background-color: #0046ac;
}

.theme-palletization_b {
  background-color: #34C6F4;
}

.custm-btn,
.theme-btn {
  position: relative;
  transition: 0.03s ease-in;
  -o-transition: 0.03s ease-in;
  -ms-transition: 0.03s ease-in;
  -moz-transition: 0.03s ease-in;
  -webkit-transition: 0.03s ease-in;
  z-index: 1;
}
.custm-btn:hover,
.theme-btn:hover {
  transition: 0.5s;
  background: #092448;
}
.custm-btn:hover::before,
.theme-btn:hover::before {
  top: 0;
}
.custm-btn:before,
.theme-btn:before {
  content: "";
  position: absolute;
  background: #092448;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: 0.3s ease-in;
  transition: top 0.3s ease-in;
}

.white-color {
  color: #ffffff;
}

.pl5 {
  padding-left: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

body {
  color: #666666;
  background: #e6e6e6;
  font-family: "HelveticaNeueLTStd-Roman";
}

label {
  font-size: 14px;
  margin-bottom: 5px;
}

.container-fluid {
  padding: 0;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.sub-title {
  font-size: 18px;
  margin: 15px 0 5px;
}

a {
  color: #092347;
}

.form-control {
  border: 1px solid #b4b4b4;
  border-radius: 0px;
  font-size: 14px;
}
.form-control:focus {
  box-shadow: none;
}

.title-bartext {
  font-size: 26px;
  padding: 12px 0;
}

.custm-form-inside {
  padding: 40px 15px;
}

.small-sub-title {
  font-size: 18px;
}

.btn-inline button + button {
  margin-left: 10px;
}

.custm-btn {
  border: 0;
  min-width: 130px;
  border-radius: 0;
  text-align: center;
}

.space10 {
  width: 20px;
}

.display-flex {
  display: flex;
}

.opacity0 {
  opacity: 0;
}

.custm-w {
  width: calc(50% - 5px);
}

.max-w-200 {
  max-width: 200px;
}

html,
body {
  height: 100%;
}

.package-radio-btn {
  padding-top: 10px;
}
.package-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.package-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #a50095;
}
.package-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.package-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.package-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.package-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #a50095;
}

.profile-radio-btn {
  padding-top: 10px;
}
.profile-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.profile-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #049a8f;
}
.profile-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.profile-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.profile-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.profile-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #049a8f;
}

.product-radio-btn {
  padding-top: 10px;
}
.product-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.product-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f6bf52;
}
.product-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.product-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.product-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.product-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f6bf52;
}

.process-radio-btn {
  padding-top: 10px;
}
.process-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.process-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0046ac;
}
.process-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.process-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.process-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.process-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0046ac;
}

.palletization-radio-btn {
  padding-top: 10px;
}
.palletization-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.palletization-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c31010;
}
.palletization-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.palletization-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.palletization-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.palletization-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #c31010;
}

.profile-radio-btn {
  padding-top: 10px;
}
.profile-radio-btn mat-radio-button + mat-radio-button {
  padding-left: 15px;
}
.profile-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #049a8f;
}
.profile-radio-btn .mat-radio-button.mat-accent .mat-radio-inner-circle,
.profile-radio-btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.profile-radio-btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.profile-radio-btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #049a8f;
}

.mw40 {
  max-width: 40px;
}

#custm-upload {
  position: absolute;
  width: 39px;
  height: 44px;
  opacity: 0;
  cursor: pointer;
}

.fs14 {
  font-size: 14px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(./assets/img/arrow.png);
  background-repeat: no-repeat;
  background-position: 98% 11px;
  background-size: 15px;
  padding-right: 35px !important;
}

.step-header ul {
  display: flex;
  list-style: none;
  margin: 20px 0;
  padding: 0px;
}
.step-header ul li {
  width: 20%;
  text-align: center;
  position: relative;
}
.step-header ul li .circle {
  position: relative;
}
.step-header ul li figure {
  display: table;
  margin: 0 auto;
  border: 2px solid #b4b4b4;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: relative;
  z-index: 9;
  background: #ffffff;
}
.step-header ul li figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}
.step-header ul li .normal {
  display: none;
}
.step-header ul li .complete-tick {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  position: relative;
  margin: 5px auto 0;
}
.step-header ul li .complete-tick img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
}
.step-header ul .figure-line {
  position: relative;
}
.step-header ul .step-line {
  width: 100%;
  height: 2px;
  background: #b4b4b4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.step-header ul .figure-content {
  margin-top: 10px;
}
.step-header ul .figure-content h6 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}
.step-header ul .figure-content p {
  font-size: 15px;
  min-height: 60px;
  max-width: 220px;
  margin: 0 auto;
  width: 100%;
  line-height: normal;
  padding-top: 5px;
}
.step-header ul .profile_section .complete-tick {
  background-color: #049a8f;
}
.step-header ul .profile_section figure img {
  width: 90px;
}
.step-header ul .profile_section.active .normal {
  display: block;
}
.step-header ul .profile_section.active .gray {
  display: none;
}
.step-header ul .profile_section.active figure {
  border: 2px solid #049a8f;
}
.step-header ul .profile_section.active .step-line {
  background: #049a8f;
}
.step-header ul .package_section .complete-tick {
  background-color: #a50095;
}
.step-header ul .package_section.active .normal {
  display: block;
}
.step-header ul .package_section.active .gray {
  display: none;
}
.step-header ul .package_section.active figure {
  border: 2px solid #a50095;
}
.step-header ul .package_section.active .step-line {
  background: #a50095;
}
.step-header ul .product_section .complete-tick {
  background-color: #f6bf52;
}
.step-header ul .product_section.active .normal {
  display: block;
}
.step-header ul .product_section.active .gray {
  display: none;
}
.step-header ul .product_section.active figure {
  border: 2px solid #f6bf52;
}
.step-header ul .product_section.active .step-line {
  background: #f6bf52;
}
.step-header ul .process_section .complete-tick {
  background-color: #0046ac;
}
.step-header ul .process_section.active .normal {
  display: block;
}
.step-header ul .process_section.active .gray {
  display: none;
}
.step-header ul .process_section.active figure {
  border: 2px solid #0046ac;
}
.step-header ul .process_section.active .step-line {
  background: #0046ac;
}
.step-header ul .Palletization_section .complete-tick {
  background-color: #34C6F4;
}
.step-header ul .Palletization_section.active .normal {
  display: block;
}
.step-header ul .Palletization_section.active .gray {
  display: none;
}
.step-header ul .Palletization_section.active figure {
  border: 2px solid #34C6F4;
}
.step-header ul .Palletization_section.active .step-line {
  background: #34C6F4;
}

.footer {
  background-image: linear-gradient(to right, #092347, #0b2e5a, #0a3a6f, #074683, #005299);
}
.footer .display-flex {
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}
.footer .display-flex .footer-circle {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 100%;
  position: relative;
}
.footer .display-flex .footer-circle img {
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  width: 24px;
  z-index: 9;
}
.footer .display-flex .footer-circle img.next-img {
  left: 52% !important;
}
.footer .display-flex .progress-bar {
  width: 500px;
  background: #b4b4b4;
  height: 23px;
  border-radius: 12px;
  display: block;
  padding: 4px;
  position: relative;
  overflow: visible;
}
.footer .display-flex .progress-bar .result-status {
  background: #0046ac;
  height: 15px;
  border-radius: 12px;
  left: 0;
}
.footer .display-flex .progress-bar .result-value {
  padding-top: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.footer .footer-outer-circle {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.display-flex-login {
  display: flex;
  justify-content: space-between;
}
.display-flex-login .mat-checkbox-label {
  color: #5a6771;
}
.display-flex-login .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.display-flex-login .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #0047ac !important;
}

.theme-card {
  margin-bottom: 1.875rem;
  border: none;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 15px;
}
.theme-card .card-header {
  background: transparent;
  border: 0;
  padding: 0 0 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.theme-card .card-header h1 {
  font-size: 20px;
  color: #000000;
}
.theme-card .card-header h1 i {
  padding-right: 10px;
}

.theme-color {
  color: #049a8f;
}

.theme-color-blue {
  color: #024f93;
}

.view-history-link {
  text-decoration: underline;
  color: #024d90;
}

.search-view-history-link {
  color: #024d90;
  float: left;
  position: relative;
  font-size: 16px;
  top: 4px;
}

.custm-pl0 {
  padding-left: 0;
}

.theme-btn {
  background: #024f93;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff !important;
}

.view-history {
  background: #049a8f;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff !important;
  white-space: nowrap;
}
.view-history:hover {
  background: #087d75;
}
.view-history:focus {
  background: #087d75;
}

.theme-delete {
  background: #ea0c0c;
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}

.p5 {
  padding: 5px 7px !important;
}

.pr5 {
  padding-right: 5px;
}

.btn .fa {
  font-size: 14px;
}

header .navbar {
  border: none !important;
  box-shadow: none !important;
  background-image: url(assets/img/header-img.jpg);
  background-size: contain;
  background-repeat-y: no-repeat;
  padding: 20px 10px;
}

.table-bordered thead {
  border-bottom: 2px solid #dddddd;
}
.table-bordered thead th {
  border-bottom: 2px solid #e3ebf3;
  border: 1px solid #e3ebf3;
  font-family: "HelveticaNeueLTStd-Bd";
  font-size: 14px;
  white-space: nowrap;
}
.table-bordered tbody tr:nth-child(2n+1) {
  background: #f3f3f3;
}
.table-bordered tbody td {
  border: 1px solid #e3ebf3;
  padding: 4px 12px;
  vertical-align: middle;
  font-family: "HelveticaNeueLTStd-Roman";
}
.table-bordered tbody td select.form-control {
  min-width: 180px;
}

*:focus {
  outline: none;
  box-shadow: none;
}

.modal-dialog .modal-header {
  background: #0047ac;
  padding: 10px 15px;
  position: relative;
}
.modal-dialog .modal-header .modal-title {
  color: #ffffff;
  font-size: 17px;
  font-family: "HelveticaNeueLTStd-Bd";
}
.modal-dialog .modal-header .close {
  color: #ffffff;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateX(-50%);
}
.modal-dialog .modal-body {
  background: rgba(0, 71, 172, 0.05);
}
.modal-dialog .modal-footer {
  padding: 12px 15px;
}

.delete-uer-body img {
  max-width: 50px;
}
.delete-uer-body p {
  color: #000000;
  font-size: 16px;
  font-family: "HelveticaNeueLTStd-Bd";
}

.alert.alert-success {
  max-width: 600px;
  margin: 0 auto 20px;
}
.alert.alert-success .close {
  cursor: pointer;
}

.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
}
.page-loader .filtter-bg {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
}
.page-loader .mat-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-loader .mat-progress-spinner circle,
.page-loader .mat-spinner circle {
  stroke: #049a8f;
}

.form-group {
  margin-bottom: 5px;
}

.container {
  max-width: 1350px;
  width: 100%;
}

.figure-count-mobile {
  display: none;
}

a {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .figure-content p {
    display: none;
  }

  .step-header ul li figure {
    width: 100px;
    height: 100px;
  }

  .step-header ul .profile_section figure img {
    width: 70px;
  }

  .step-header ul li figure img {
    width: 50px;
  }

  .step-header ul .figure-content h6 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }
}
.step-header ul li figure {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.container.custm-form-inside .row {
  align-items: flex-end;
}

.step-header ul .profile_section figure img {
  width: 70px;
}

.step-header ul li figure img {
  width: 50px;
}

.step-header ul .figure-content h6 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .figure-line {
    display: none;
  }

  .btn-inline .btn {
    margin: 0px 10px 10px 0px !important;
  }

  .title-bartext {
    font-size: 20px;
    padding: 12px 0;
  }

  .step-header ul .figure-content h6 {
    display: none;
  }

  .figure-count-mobile {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
}
.custm-whitespace {
  white-space: nowrap;
}

.DigitalSign {
  padding: 0px 0px 30px;
  font-size: 20px;
}
.DigitalSign input[type=checkbox] {
  height: 20px;
  width: 20px;
  position: relative;
  top: 2px;
}
.DigitalSign .name-sign {
  margin-top: 20px;
}
.DigitalSign .name-sign .form-control {
  display: inline-block;
  max-width: 300px;
  margin-left: 10px;
}
.DigitalSign .name-sign label {
  font-size: 16px;
}

.ml10 {
  margin-left: 8px;
}

.custm-pagination .current {
  background: #024f93 !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.custm-multi-select .selected-list {
  border: 1px solid #b4b4b4;
  background: #ffffff;
}

.custm-multi-select .selected-list .c-list .c-token {
  border: 1px solid #0047ac;
  text-align: center;
}

.custm-multi-select .selected-list .c-list .c-token .c-remove svg {
  fill: red;
}

.custm-multi-select .selected-list .c-btn {
  min-height: 35px;
}

.pure-checkbox input[type=checkbox] + label {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
  font-weight: 300;
}

.pure-checkbox input[type=checkbox] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.pure-checkbox input[type=checkbox]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: #0079FE;
}

.pure-checkbox input[type=checkbox] + label:before {
  box-sizing: content-box;
  content: "";
  color: #0079FE !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #0079FE !important;
  text-align: center;
  transition: all 0.4s ease;
}