/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

@font-face {
  font-family: "HelveticaNeueLTStd-Bd";
  src: url("./assets/fonts/HelveticaNeueLTStd-Bd.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Bd.otf") format("opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Bd.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueLTStd-Bd.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("./assets/fonts/HelveticaNeueLTStd-Roman.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Roman.otf") format("opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Roman.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueLTStd-Roman.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Md";
  src: url("./assets/fonts/HelveticaNeueLTStd-Md.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Md.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueLTStd-Md.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Lt";
  src: url("./assets/fonts/HelveticaNeueLTStd-Lt.eot") format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Lt.otf") format("opentype"),
    url("./assets/fonts/HelveticaNeueLTStd-Lt.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueLTStd-Lt.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.hel75,
.bold {
  font-family: "HelveticaNeueLTStd-Bd";
}

.hel55 {
  font-family: "HelveticaNeueLTStd-Roman";
}

.hel65 {
  font-family: "HelveticaNeueLTStd-Md";
}

.hel45 {
  font-family: "HelveticaNeueLTStd-Lt";
}

.mb-10 {
  margin-bottom: 10px;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.bcolor {
  color: #000000;
}

.theme-profile_c {
  color: #049a8f;
}

.theme-package_c {
  color: #a50095;
}

.theme-product_c {
  color: #f6bf52;
}

.theme-process_c {
  color: #0046ac;
}
.theme-sign_b {
  background: #092347;
}
.theme-palletization_c {
  color: #34C6F4;
}

.theme-profile_b {
  background-color: #049a8f;
}

.theme-package_b {
  background-color: #a50095;
}

.theme-product_b {
  background-color: #f6bf52;
}

.theme-process_b {
  background-color: #0046ac;
}

.theme-palletization_b {
  background-color: #34C6F4;
}

// button.theme-profile_b{
//   &:before {
//     background-color: #088a80;
//   }
// }

// button.theme-package_b{
//   &:before {
//     background-color: #960288;
//   }
// }

// button.theme-product_b{
//   &:before {
//     background-color: #efb138;
//   }
// }

// button.theme-process_b{
//   &:before {
//     background-color: #033c8e;
//   }
// }

// button.theme-palletization_b{
//   &:before {
//     background-color: #d00707;
//   }
// }

.custm-btn,
.theme-btn {
  position: relative;
  transition: 0.03s ease-in;
  -o-transition: 0.03s ease-in;
  -ms-transition: 0.03s ease-in;
  -moz-transition: 0.03s ease-in;
  -webkit-transition: 0.03s ease-in;
  z-index: 1;

  &:hover {
    transition: 0.5s;
    background: #092448;

    &::before {
      top: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    background: #092448;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: 0.3s ease-in;
    transition: top 0.3s ease-in;
  }
}

.white-color {
  color: #ffffff;
}

.pl5 {
  padding-left: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

body {
  color: #666666;
  background: #e6e6e6;
  font-family: "HelveticaNeueLTStd-Roman";
}

label {
  font-size: 14px;
  margin-bottom: 5px;
}

.container-fluid {
  padding: 0;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.sub-title {
  font-size: 18px;
  margin: 15px 0 5px;
}

a {
  color: #092347;
}

.form-control {
  border: 1px solid #b4b4b4;
  border-radius: 0px;
  font-size: 14px;

  &:focus {
    box-shadow: none;
  }
}

.title-bartext {
  font-size: 26px;
  padding: 12px 0;
}

.custm-form-inside {
  padding: 40px 15px;
}

.small-sub-title {
  font-size: 18px;
}

.btn-inline {
  button + button {
    margin-left: 10px;
  }
}

.custm-btn {
  border: 0;
  min-width: 130px;
  border-radius: 0;
  text-align: center;
}

.space10 {
  width: 20px;
}

.display-flex {
  display: flex;
}

.opacity0 {
  opacity: 0;
}

.custm-w {
  width: calc(50% - 5px);
}

.max-w-200 {
  max-width: 200px;
}

html,
body {
  height: 100%;
}

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }
.package-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #a50095;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #a50095;
  }
}

.profile-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #049a8f;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #049a8f;
  }
}

.product-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f6bf52;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #f6bf52;
  }
}

.process-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0046ac;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #0046ac;
  }
}

.palletization-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #c31010;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #c31010;
  }
}

.profile-radio-btn {
  padding-top: 10px;

  mat-radio-button + mat-radio-button {
    padding-left: 15px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #049a8f;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #049a8f;
  }
}

.mw40 {
  max-width: 40px;
}

#custm-upload {
  position: absolute;
  width: 39px;
  height: 44px;
  opacity: 0;
  cursor: pointer;
}

.fs14 {
  font-size: 14px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(./assets/img/arrow.png);
  background-repeat: no-repeat;
  background-position: 98% 11px;
  background-size: 15px;
  padding-right: 35px !important;
}

.step-header {
  ul {
    display: flex;
    list-style: none;
    margin: 20px 0;
    padding: 0px;

    li {
      width: 20%;
      text-align: center;
      position: relative;

      .circle {
        position: relative;
      }

      figure {
        display: table;
        margin: 0 auto;
        border: 2px solid #b4b4b4;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        position: relative;
        z-index: 9;
        background: #ffffff;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70px;
        }
      }

      .normal {
        display: none;
      }

      .complete-tick {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        position: relative;
        margin: 5px auto 0;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 22px;
        }
      }
    }

    .figure-line {
      position: relative;
    }

    .step-line {
      width: 100%;
      height: 2px;
      background: #b4b4b4;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .figure-content {
      margin-top: 10px;

      h6 {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
      }

      p {
        font-size: 15px;
        min-height: 60px;
        max-width: 220px;
        margin: 0 auto;
        width: 100%;
        line-height: normal;
        padding-top: 5px;
      }
    }

    .profile_section {
      .complete-tick {
        background-color: #049a8f;
      }

      figure {
        img {
          width: 90px;
        }
      }

      &.active {
        .normal {
          display: block;
        }

        .gray {
          display: none;
        }

        figure {
          border: 2px solid #049a8f;
        }

        .step-line {
          background: #049a8f;
        }
      }
    }

    .package_section {
      .complete-tick {
        background-color: #a50095;
      }

      &.active {
        .normal {
          display: block;
        }

        .gray {
          display: none;
        }

        figure {
          border: 2px solid #a50095;
        }

        .step-line {
          background: #a50095;
        }
      }
    }

    .product_section {
      .complete-tick {
        background-color: #f6bf52;
      }

      &.active {
        .normal {
          display: block;
        }

        .gray {
          display: none;
        }

        figure {
          border: 2px solid #f6bf52;
        }

        .step-line {
          background: #f6bf52;
        }
      }
    }

    .process_section {
      .complete-tick {
        background-color: #0046ac;
      }

      &.active {
        .normal {
          display: block;
        }

        .gray {
          display: none;
        }

        figure {
          border: 2px solid #0046ac;
        }

        .step-line {
          background: #0046ac;
        }
      }
    }

    .Palletization_section {
      .complete-tick {
        background-color: #34C6F4;
      }

      &.active {
        .normal {
          display: block;
        }

        .gray {
          display: none;
        }

        figure {
          border: 2px solid #34C6F4;
        }

        .step-line {
          background: #34C6F4;
        }
      }
    }
  }
}

.footer {
  background-image: linear-gradient(to right, #092347, #0b2e5a, #0a3a6f, #074683, #005299);

  .display-flex {
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;

    .footer-circle {
      width: 50px;
      height: 50px;
      background: #ffffff;
      border-radius: 100%;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 42%;
        transform: translate(-50%, -50%);
        width: 24px;
        z-index: 9;

        &.next-img {
          left: 52% !important;
        }
      }
    }

    .progress-bar {
      width: 500px;
      background: #b4b4b4;
      height: 23px;
      border-radius: 12px;
      display: block;
      padding: 4px;
      position: relative;
      overflow: visible;

      .result-status {
        background: #0046ac;
        height: 15px;
        border-radius: 12px;
        left: 0;
      }

      .result-value { 
        padding-top: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
      }
    }
  }

  .footer-outer-circle {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.display-flex-login {
  display: flex;
  justify-content: space-between;

  .mat-checkbox-label {
    color: #5a6771;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #0047ac !important;
  }
}

.theme-card {
  margin-bottom: 1.875rem;
  border: none;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 15px;

  .card-header {
    background: transparent;
    border: 0;
    padding: 0 0 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 20px;
      color: #000000;

      i {
        padding-right: 10px;
      }
    }
  }
}

.theme-color {
  color: #049a8f;
}
.theme-color-blue {
  color: #024f93;
}
.view-history-link {
  text-decoration: underline;
  color: #024d90;
}
.search-view-history-link {
  color: #024d90;
  float: left;
  position: relative;
  font-size: 16px;
  top: 4px;
}
.custm-pl0 {
  padding-left: 0;
}
.theme-btn {
  background: #024f93;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff !important;
}

.view-history {
  background: #049a8f;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff !important;
  white-space: nowrap;

  &:hover {
    background: #087d75;
  }

  &:focus {
    background: #087d75;
  }
}

.theme-delete {
  background: #ea0c0c;
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}

.p5 {
  padding: 5px 7px !important;
}

.pr5 {
  padding-right: 5px;
}

.btn {
  .fa {
    font-size: 14px;
  }
}

header {
  .navbar {
    // background-image: linear-gradient(to right, #092347, #0b2e5a, #0a3a6f, #074683, #005299);
    //     background: -webkit-gradient(left top, left bottom, color-stop(12%, rgba(9,35,70,1)), color-stop(20%, rgba(9,35,70,1)), color-stop(30%, rgba(9,35,70,1)), color-stop(37%, rgba(9,35,70,1)), color-stop(42%, rgba(28,52,84,1)), color-stop(100%, rgba(245,247,250,1)));
    // background: -webkit-linear-gradient(top, rgba(9,35,70,1) 12%, rgba(9,35,70,1) 20%, rgba(9,35,70,1) 30%, rgba(9,35,70,1) 37%, rgba(28,52,84,1) 42%, rgba(245,247,250,1) 100%);
    // background: -o-linear-gradient(top, rgba(9,35,70,1) 12%, rgba(9,35,70,1) 20%, rgba(9,35,70,1) 30%, rgba(9,35,70,1) 37%, rgba(28,52,84,1) 42%, rgba(245,247,250,1) 100%);
    // background: -ms-linear-gradient(top, rgba(9,35,70,1) 12%, rgba(9,35,70,1) 20%, rgba(9,35,70,1) 30%, rgba(9,35,70,1) 37%, rgba(28,52,84,1) 42%, rgba(245,247,250,1) 100%);
    // background: linear-gradient(to bottom, rgba(9,35,70,1) 12%, rgba(9,35,70,1) 20%, rgba(9,35,70,1) 30%, rgba(9,35,70,1) 37%, rgba(28,52,84,1) 42%, rgba(245,247,250,1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#092346', endColorstr='#f5f7fa', GradientType=0 );

    border: none !important;
    box-shadow: none !important;
    background-image: url(assets/img/header-img.jpg);
    background-size: contain;
    background-repeat-y: no-repeat;
    padding: 20px 10px;
  }
}

.table-bordered {
  thead {
    border-bottom: 2px solid #dddddd;

    th {
      border-bottom: 2px solid #e3ebf3;
      border: 1px solid #e3ebf3;
      font-family: "HelveticaNeueLTStd-Bd";
      font-size: 14px;
      white-space: nowrap;
    }
  }

  tbody {
    tr:nth-child(2n + 1) {
      background: #f3f3f3;
    }

    td {
      border: 1px solid #e3ebf3;
      padding: 4px 12px;
      vertical-align: middle;
      font-family: "HelveticaNeueLTStd-Roman";

      select.form-control {
        min-width: 180px;
      }
    }
  }
}

* {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.modal-dialog {
  .modal-header {
    background: #0047ac;
    padding: 10px 15px;
    position: relative;

    .modal-title {
      color: #ffffff;
      font-size: 17px;
      font-family: "HelveticaNeueLTStd-Bd";
    }

    .close {
      color: #ffffff;
      opacity: 1;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateX(-50%);
    }
  }

  .modal-body {
    background: rgba(0, 71, 172, 0.05);
  }

  .modal-footer {
    padding: 12px 15px;
  }
}

.delete-uer-body {
  img {
    max-width: 50px;
  }

  p {
    color: #000000;
    font-size: 16px;
    font-family: "HelveticaNeueLTStd-Bd";
  }
}

.alert.alert-success {
  max-width: 600px;
  margin: 0 auto 20px;

  .close {
    cursor: pointer;
  }
}

.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9;

  .filtter-bg {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .mat-spinner {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #049a8f;
  }
}

.form-group {
  margin-bottom: 5px;
}

.container {
  max-width: 1350px;
  width: 100%;
}

.figure-count-mobile {
  display: none;
}
a {
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .figure-content p {
    display: none;
  }

  .step-header ul li figure {
    width: 100px;
    height: 100px;
  }

  .step-header ul .profile_section figure img {
    width: 70px;
  }

  .step-header ul li figure img {
    width: 50px;
  }

  .step-header ul .figure-content h6 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.step-header ul li figure {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.container.custm-form-inside .row {align-items: flex-end;}

.step-header ul .profile_section figure img {
  width: 70px;
}

.step-header ul li figure img {
  width: 50px;
}

.step-header ul .figure-content h6 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .figure-line {
    display: none;
  }

  .btn-inline .btn {
    margin: 0px 10px 10px 0px !important;
  }

  .title-bartext {
    font-size: 20px;
    padding: 12px 0;
  }

  .step-header ul .figure-content h6 {
    display: none;
  }

  .figure-count-mobile {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
}
.custm-whitespace{
white-space: nowrap;
}
.DigitalSign {
  padding: 0px 0px 30px;
  font-size: 20px;

  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    position: relative;
    top: 2px;
  }

  .name-sign {
    margin-top: 20px;
    .form-control {
    display: inline-block;
    max-width: 300px;
    margin-left: 10px;
  }
 label {
    font-size: 16px;
  }
  }
}
.ml10{margin-left:8px;}
.custm-pagination {
  .current {
    background: #024f93 !important;
  }
}
@import "~ngx-toastr/toastr";

.custm-multi-select .selected-list{
  border: 1px solid #b4b4b4;
  background: #ffffff;
}

.custm-multi-select .selected-list .c-list .c-token {
  border: 1px solid #0047ac;
  text-align: center;
}
.custm-multi-select .selected-list .c-list .c-token .c-remove svg {
  fill: red;
}
.custm-multi-select .selected-list .c-btn {
  min-height: 35px;
}
.pure-checkbox input[type="checkbox"] + label {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
  font-weight: 300;
}

.pure-checkbox input[type="checkbox"] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-checkbox input[type="checkbox"]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: #0079FE;
}

.pure-checkbox input[type="checkbox"] + label:before {
  box-sizing: content-box;
  content: '';
  color: #0079FE !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #0079FE !important;
  text-align: center;
  transition: all 0.4s ease;
}


